import { createRef } from 'react';
import EmailSubsciptionContainer from '../email-subscribe/EmailSubscriptionContainer';
import './../../css/Menu.css';

export default function Menu(props) {
  const dialogRef = createRef();

  function closeDialog(event) { 
    if (event.target !== dialogRef.current) return;
    dialogRef.current.close();
  }

  return(
    <nav className="menu-nav" style={props.style}>
      <div>
        <button className="button c-black" onClick={() => dialogRef.current.showModal()}>Join email list</button>
        <dialog ref={dialogRef} onClick={closeDialog}>
          <EmailSubsciptionContainer dialogRef={dialogRef}/>
        </dialog>
      </div>
    </nav>
  );
}