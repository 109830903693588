const data = [];

for (let i = 0; i < 5000; i++) {
  data.push({
    tokenId: `Token ${i}`,
    sha256Hash: `sha256 hash ${i}`,
    anotherHash: `hash value ${i}`,
  });
}

export default function ProvenanceTable() {
  return (
    <table>
      <thead>
        <tr>
          <th>Token ID</th>
          <th>sha256 Hash</th>
          <th>Another Hash</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.tokenId}</td>
            <td>{item.sha256Hash}</td>
            <td>{item.anotherHash}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
