export const navigationMenu = {
  "Home": "/",
  "About": "/about"
};


export default function NavigationBar(props) {
  const { overlayMode = true } = props;

  return(
    <nav className="nav-bar">
      <ul className="nav-bar">
        {
          Object.keys(navigationMenu)
            .filter(key => navigationMenu[key] !== window.location.pathname)
            .map(key => (
              <li className="nav-bar-item" key={key}>
                <a className={overlayMode ? "c-white" : "c-black"} href={navigationMenu[key]}>
                  <span>{key}</span>
                </a>
              </li>
            )
          )
        }
      </ul>
    </nav>
  );
}