import { useEffect, useState } from "react";
import "../css/BaseSubPage.css";
import NavigationBar from "./NavigationBar";
import logoImage from '../resources/images/logoBlack.png';
import InstagramIcon from '../resources/icons/Instagram';
import OpenseaIcon from '../resources/icons/Opensea';
import TwitterIcon from '../resources/icons/Twitter';

const maxWidthMobile = 820;

export default function BaseSubPage({children, overlayMode = true}) {
  const color = overlayMode ? "white" : "black";

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const offset = (width > maxWidthMobile) ? "100px" : "20px";
  const style = { paddingLeft: offset, paddingRight: offset };

  return(
    <div>
      <a href="/"><img className="nav-bar-img" src={logoImage} alt="navigation-bar-logo"/></a>
      <div style={style}>

        <div className="main-content">{children}</div>
        <NavigationBar overlayMode={overlayMode}/>

        <div className="link-container">
          <ul className="link-icon-bar">
            <li className="link-icon-item"><OpenseaIcon color={color}/></li>
            <li className="link-icon-item"><InstagramIcon color={color}/></li>
            <li className="link-icon-item"><TwitterIcon color={color}/></li>
          </ul>
        </div>

        <div className="background-wallpaper"/>
        { overlayMode ? <div className="background-wallpaper-overlay"/> : null }
      </div>
    </div>
  );
}