import { useRouteError } from "react-router-dom";
import roadClosedImage from "../resources/images/roadclosed.jpeg"
import BaseSubPage from "../components/BaseSubPage";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <BaseSubPage>
      <div id="error-page">
        <h1 className="c-white disabled text-center">error</h1>
        <img style={{display: "block", padding: "10px", marginLeft: "auto", marginRight: "auto", width: "100%", height: "100%", maxWidth: "1000px"}} src={roadClosedImage} alt="road-cloased"/>
      </div>
    </BaseSubPage>
  );
}