import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import AboutPage from "./pages/About";
import Provenance from "./pages/Provenance";
import Staking from "./pages/Staking";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
    errorElement: <ErrorPage />,
  }
]);

function App() {
  return (<RouterProvider router={router} />);
}


export default App;
