import Menu from '../components/home/Menu';
import BaseSubPage from "../components/BaseSubPage";

export default function Home() {

  return (
    <BaseSubPage overlayMode={false}>
      <div className="contents-container">
        <Menu style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'auto'}}/>
      </div>
      <div className="background-wallpaper background-black"/>
    </BaseSubPage>
  );
}
