import { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "../../css/EmailSubscription.css";

export default function EmailSubsciptionContainer({ dialogRef }) {
  const mailchimpEndpoint = `https://streetcarsandcoffee.us9.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe url={mailchimpEndpoint} render={({ subscribe, status, message }) => (
        <CustomForm
          dialogRef={dialogRef}
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )} />
    </div>
  );
};

function CustomForm ({ dialogRef, status, message, onValidated }) {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if(status === "success") { setEmail(''); }
  }, [status])

  const title = status === "success" ? "Subscribed" : "Join our email list for future updates";

  function handleSubmit(e) {
    e.preventDefault();
    email && email.indexOf("@") > -1 && onValidated({EMAIL: email});
  }

  return (
    <form className="mc__form" onSubmit={handleSubmit}>
      <h4 className="mc__title">{title}</h4>

      {status === "sending" && (<div className="mc__alert mc__alert--sending">sending...</div>)}
      {status === "error" && (<div className="mc__alert mc__alert--error" dangerouslySetInnerHTML={{ __html: message }}/>)}
      {status === "success" && (<div className="mc__alert mc__alert--success" dangerouslySetInnerHTML={{ __html: message }}/>)}

      {status !== "success" ? (
        <div className="mc__field-container">
          <label className="c-white">
            Email
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="your@email.com"
              value={email}
              isRequired
            />
          </label>
        </div>
      ) : null}

      {status === null && <input className='button c-white' type='submit' value="subscribe"/>}
    </form>
  );
};
