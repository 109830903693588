import BaseSubPage from "../components/BaseSubPage";
import aboutImage from "../resources/images/about.png"
import "./../css/Index.css";

export default function AboutPage() {
  return (
    <BaseSubPage>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h1 className="c-white disabled">About</h1>
        <img style={{display: "block", padding: "10px", marginLeft: "auto", marginRight: "auto", width: "100%", maxWidth: "720px"}} src={aboutImage} alt="about-image"/>
        <p className="c-white disabled">
          We are excited to unveil our upcoming NFT collection, STREETCAR - a unique collection of street-fashion-inspired customized cars. Cars have always been a symbol of freedom, style, and personal expression. While the world is rapidly shifting towards electric cars, there is a group of people who cherish the traditional cars with real engines and adore the art of customization - not just under the hood, but also on the outside. For them, tuning their cars' performance and aesthetics is a way of expressing themselves. This is where the world of street fashion meets the world of car culture. Our NFT collection celebrates the creativity and passion of car enthusiasts who express themselves through their unique customized cars. So, what does your car say about you? Get ready to explore the world of STREETCAR.
        </p>
      </div>
    </BaseSubPage>
  );
}